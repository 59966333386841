<template>
  <ClientOnly>
    <v-alert
      class="mb-3"
      variant="outlined"
      type="error"
      density="compact"
      border="start"
    >
      {{ errorMessage }}
    </v-alert>
    <ul
      v-if="errorDetail"
      class="error-message-list"
    >
      <li
        v-for="error in errorDetail"
        :key="error.code"
        class="error-message-list-item red--text text--lighten-2"
      >
        {{ error.message }}
      </li>
    </ul>
  </ClientOnly>
</template>

<script lang="ts">
export default defineComponent({
  name: 'BaseErrorMessage',
  props: {
    errorMessage: {
      type: String,
      required: false,
      default: 'エラーが発生しました'
    },
    errorDetail: {
      type: [Array, Object],
      required: false,
      default: () => []
    }
  },
  setup() {
    return {};
  }
});
</script>

<style>
ul.error-message-list {
  padding: 0 0 0 24px;
  color: red;
}
ul li.error-message-list-item {
  display: list-item;
  list-style: disc;
  padding: 0;
}
@media screen and (max-width: 480px) {
  ul.error-message-list {
    padding-left: 0;
  }
}
</style>
